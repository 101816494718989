<template>
	<div class="px-4 py-4">
		<div class="flex justify-center">
			<div class="px-2">
				<label for="x" class="block text-sm font-medium text-gray-700"
					>X-axis</label
				>
				<div class="mt-1">
					<input
						type="number"
						name="x"
						id="x"
						min="0"
						max="10"
						step=".1"
						@change="makeCustomer(x, y, z)"
						class="block w-24 px-2 py-1 bg-blue-100 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
						v-model="x"
					/>
				</div>
			</div>
			<div class="px-2">
				<label for="y" class="block text-sm font-medium text-gray-700"
					>Y-axis</label
				>
				<div class="mt-1">
					<input
						type="number"
						name="y"
						id="y"
						min="0"
						max="10"
						step=".1"
						@change="makeCustomer(x, y, z)"
						class="block w-24 px-2 py-1 bg-blue-100 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
						v-model="y"
					/>
				</div>
			</div>
			<div class="px-2">
				<label for="z" class="block text-sm font-medium text-gray-700"
					>Z-axis</label
				>
				<div class="mt-1">
					<input
						type="number"
						name="z"
						id="z"
						min="0"
						max="10"
						step=".1"
						@change="makeCustomer(x, y, z)"
						class="block w-24 px-2 py-1 bg-blue-100 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
						v-model="z"
					/>
				</div>
			</div>
		</div>
		<div class="w-full text-center">
			<div>
				<button
					class="w-40 px-4 py-2 mx-auto mt-2 text-blue-800 bg-blue-200 rounded hover:bg-blue-300"
					@click="makeCustomer(x, y, z)"
				>
					Build Scale Cube
				</button>
			</div>
		</div>
		<div class="w-full ">
			<svg
				id="svg"
				xmlns="http://www.w3.org/2000/svg"
				class="w-full m-auto text-center"
				style="max-height: 500px"
				:viewBox="viewBox"
			>
				<g :stroke="akf_color" stroke-width="1">
					<line
						v-for="(line, index) in sc"
						:key="index"
						:x1="line.x1"
						:y1="line.y1"
						:x2="line.x2"
						:y2="line.y2"
						:stroke-dasharray="line.dash"
					/>
				</g>
				<g :stroke="client_color" stroke-width="1">
					<line
						v-for="(line, index) in customer"
						:key="index"
						:x1="line.x1"
						:y1="line.y1"
						:x2="line.x2"
						:y2="line.y2"
						:stroke-dasharray="line.dash"
					/>
				</g>
				<g :stroke="akf_color" stroke-width="1" v-if="sc.length > 10">
					<line
						v-for="(line, index) in [sc[1], sc[2], sc[10]]"
						:key="index"
						:x1="line.x1"
						:y1="line.y1"
						:x2="line.x2"
						:y2="line.y2"
					/>
				</g>
				<g>
					<circle
						:cx="akf_circle_pos.x - 1"
						:cy="akf_circle_pos.y + 1"
						r="7"
						:stroke="akf_color"
						fill="none"
						stroke-width=".5"
					/>
				</g>

				<!-- <svg
					xmlns="http://www.w3.org/2000/svg"
					class="w-6 h-6"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
					/>
				</svg> -->
				<svg
					xmlns="http://www.w3.org/2000/svg"
					:fill="client_color"
					:x="icon_pos.x"
					:y="icon_pos.y"
				>
					<path
						fill-rule="evenodd"
						d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
						clip-rule="evenodd"
					/>
				</svg>
			</svg>
		</div>
		<div class="w-full text-center">
			<button
				class="px-4 py-2 mx-auto mt-2 text-center text-blue-800 bg-blue-200 rounded hover:bg-blue-300"
				@click="download"
			>
				Download SVG
			</button>
		</div>
	</div>
</template>

<script>
const DRAW_SIZE = 100;
const VIEW_SIZE = DRAW_SIZE * 1.75;
const VIEW_BOX = `0 0 ${VIEW_SIZE} ${VIEW_SIZE}`;
const Y_BORDER = 20;
const X_BORDER = 5;

/* eslint-disable */
import FileSaver from "file-saver";
export default {
	props: ["inputX", "inputY", "inputZ"],
	data() {
		return {
			x: 9,
			y: 9,
			z: 9,
			akf_color: "#085193",
			client_color: "#92CF50",
			sc: null,
			viewBox: VIEW_BOX,
			customer: [],
			vm: this,
		};
	},
	beforeMount() {
		this.sc = this.makeCube(10, 10, 10, {circle: true});
		this.makeCustomer(this.x, this.y, this.z);
	},
	watch: {
		inputX: {
			handler(val) {
				if(isNaN(val)) return
				this.x = val;
				this.makeCustomer(this.x, this.y, this.z);
			},
			immediate: true
		},
		inputY: {
			handler(val) {
				if(isNaN(val)) return
				this.y = val;
				this.makeCustomer(this.x, this.y, this.z);
			},
			immediate: true
		},
		inputZ: {
			handler(val) {
				if(isNaN(val)) return
				this.z = val;
				this.makeCustomer(this.x, this.y, this.z);
			},
			immediate: true
		},
	},
	methods: {
		download: function() {
			console.log("FileSaver", FileSaver);
			let el = document.querySelector("#svg");
			var serializer = new XMLSerializer();
			var source = serializer.serializeToString(svg);
			console.log("el", source);
			source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
			var blob = new Blob([source], {
				type: "image/svg+xml",
			});
			FileSaver.saveAs(blob, "scale-cube.svg");
		},
		makeCustomer: function(x, y, z) {
			console.log("started", this);
			this.customer = this.makeCube(x, y, z, { dash: "3 1", inset: 1 });
			// console.log('output', output)
		},
		makeCube: function(_x, _y, _z, options) {
			options = options || {};
			const dash = options.dash || "3 1";
			const inset = options.inset || 0;

			console.log("inside makeCube");
			const x = _x / 10;
			const y = _y / 10;
			const z = _z / 10;
			const size = DRAW_SIZE;
			const backOffset = size / 2;

			const xsize = size * x;
			const ysize = size * y;
			const zsize = backOffset * z;

			const fbl = {
				x: X_BORDER + inset,
				y: Y_BORDER + size + backOffset - inset,
			};
			const fbr = {
				x: X_BORDER + xsize - inset,
				y: Y_BORDER + size + backOffset - inset,
			};
			const ftr = {
				x: X_BORDER + xsize - inset,
				y: Y_BORDER + size - ysize + backOffset + inset,
			};
			const ftl = {
				x: X_BORDER + inset,
				y: Y_BORDER + size - ysize + backOffset + inset,
			};

			const bbl = {
				x: X_BORDER + backOffset * z + inset,
				y: Y_BORDER + size + backOffset - zsize - inset,
			};
			const bbr = {
				x: X_BORDER + backOffset * z + xsize - inset,
				y: Y_BORDER + size + backOffset - zsize - inset,
			};
			const btl = {
				x: X_BORDER + backOffset * z + inset,
				y: Y_BORDER + size + backOffset - zsize - ysize + inset,
			};
			const btr = {
				x: X_BORDER + backOffset * z + xsize - inset,
				y: Y_BORDER + size + backOffset - zsize - ysize + inset,
			};
			this.icon_pos = { x: btr.x - 10, y: btr.y - 20 };
			if (!this.akf_circle_pos && options.circle) {
				this.akf_circle_pos = { x: btr.x, y: btr.y };
			}

			return [
				// front face
				{ x1: fbl.x, y1: fbl.y, x2: fbr.x, y2: fbr.y },
				{ x1: fbr.x, y1: fbr.y, x2: ftr.x, y2: ftr.y },
				{ x1: ftr.x, y1: ftr.y, x2: ftl.x, y2: ftl.y },
				{ x1: ftl.x, y1: ftl.y, x2: fbl.x, y2: fbl.y },

				// back face
				{ x1: bbl.x, y1: bbl.y, x2: bbr.x, y2: bbr.y, dash: dash },
				{ x1: bbr.x, y1: bbr.y, x2: btr.x, y2: btr.y },
				{ x1: btr.x, y1: btr.y, x2: btl.x, y2: btl.y },
				{ x1: btl.x, y1: btl.y, x2: bbl.x, y2: bbl.y, dash: dash },

				// connecting lines
				{ x1: fbl.x, y1: fbl.y, x2: bbl.x, y2: bbl.y, dash: dash },
				{ x1: fbr.x, y1: fbr.y, x2: bbr.x, y2: bbr.y },
				{ x1: ftr.x, y1: ftr.y, x2: btr.x, y2: btr.y },
				{ x1: ftl.x, y1: ftl.y, x2: btl.x, y2: btl.y },
			];
		},
	},
};
/*

https://github.com/eligrey/FileSaver.js/
*/
</script>

<style lang="scss" scoped></style>
